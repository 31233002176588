import {getReduxStore} from "../ReduxStore/store";

export default class ReduxReader {

  getStore() {
    let store = getReduxStore();

    if (!store || !store.getState) {
      console.error("ReduxReader - cannot find global store")
    }

    return store;
  }

  getState() {
    let store = this.getStore();
    return store ? store.getState() : {};
  }

  getSlice(name, fallback = {}) {
    let s = this.getState();
    return s ? s[name] : fallback;
  }

  getObject(sliceName, name, fallback = null) {
    let slice = this.getSlice(sliceName);

    if (slice && slice.hasOwnProperty(name))
      return slice[name];

    return fallback;
  }

}
