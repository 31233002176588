// 2022-02 Some utility functions for working with Redux.
// See also ReduxReader

import _ from 'lodash';
import {getReduxStore} from "../ReduxStore/store";
import scrapyardSlice from "../ReduxStore/scrapyardSlice";

/**
 * @deprecated
 *
  */
export const getDispatch = ({dispatch} = {}) => {
  return dispatch || getReduxStore().dispatch;
};

/**
 * A simple global function that works with or without a 'dispatch'
 * param
 *
 * @param error exception object or text
 * @param dispatch optional
 * @returns {*}
 */
export function displayError({error, dispatch}) {
  dispatch ||= getDispatch();

  error = error?.toString() || "Unknown error"

  console.error(`Displaying error message: ${error}`);

  let event = scrapyardSlice.actions.setError({
    error: error
  });

  dispatch(event);
  return event;
}

globalThis.displayError = displayError;

/**
 * Invoke a function in the 'actions' hash of some slice.
 *
 * @param slice
 * @param action
 * @param payload
 * @param store
 * @param dispatch
 * @param callerName
 */
export const invokeSliceAction = ({slice, action, payload, store, dispatch, callerName='ReduxHelpers'}) => {
  dispatch ||= getDispatch();
  if (!dispatch)
    throw new Error(`${callerName}.invokeSliceAction: no dispatch function can be found`);

  let actionFunction = (typeof action == 'function') ? action : _.get(slice, ['actions', action]);

  if (!actionFunction)
    throw new Error(`${callerName}.invokeSliceAction: cannot find action function ${action}`);

  return dispatch(actionFunction(payload));
}

export const safeInvokeSliceAction = (args) => {
  try {
    invokeSliceAction(args);
  } catch (err) {
    let { callerName = 'ReduxHelpers', action } = (args || {});
    console.error(`${callerName} - invokeSliceAction ERROR: ${err}` )
  }
}

