export function getMatchingClasses(node, regex) {
  if (!node?.classList)
    return [];

  return Array.from(node.classList)
    .filter(klass => klass.toString().match(regex));
}

export function removeMatchingClasses(node, regex) {
  const classes = getMatchingClasses(node, regex);

  if (!_.isEmpty(classes)) {
    const classList = node.classList;
    classes.forEach( klass => classList.remove(klass));
  }

  return classes;
}

/**
 * Given a node, look for the nearest ancestor
 * @param node
 * @param klass
 * @returns {*|null}
 */
export function findNearestAncestorWithClass(node, klass, {quiet = false} = {}) {
  const result = findNearestAncestor(node, (ancestor) => {
    let classList = ancestor.classList;
    return classList?.contains && classList.contains(klass);
  })

  if (!result && !quiet)
    console.warn(`failed to find ancestor with class ${klass} for node: `, node);

  return result;
}

export function findNearestAncestor(node, fn) {
  let ancestor = node?.parentNode;
  while (ancestor) {
    if (fn(ancestor)) {
      return ancestor;
    }
    // failed, go up the chain
    ancestor = ancestor.parentNode;
  }
  return null;
}

export function findNodeOrAncestor(node, fn) {
  if (fn(node))
    return node;
  return findNearestAncestor(node, fn);
}

export function textToHtml(text) {
  const parser = new DOMParser();
  return parser.parseFromString(text, "text/html");
}
