/*
 * Klaviyo - extracted from view templates 2019-01.
 *
 */
import SpeakerToAnything from "./SpeakerToAnything";

// BADLY NAMED.
import KlaviyoClient from './Klaviyo/KlaviyoClient';
import UserStash from "../User/UserStash";
import {userToKlaviyoProps, vehicleToKlaviyoProps} from "./Klaviyo/KlaviyoHelpers";

export default class SpeakerToKlaviyo extends SpeakerToAnything {

  isLoaded() {
    // Klaviyo 2018-03 _learnq will become an Object if it succeeds.
    return (typeof global._learnq == "object");
  }

  preconfigure() {

  }

  getScriptUrl() {
    // 2022-05 migrated to footer_scripts.haml
    // return 'https://a.klaviyo.com/media/js/analytics/analytics.js';
  }

  getClient() {
    return (this._client ||
      (this._client = new KlaviyoClient()));
  }

  withUser(callback) {
    let user = new UserStash().getUser();
    if (user) {
      return callback(user);
    } else {
      console.log("SpeakerToKlaviyo: user is unknown, operation canceled.");
    }
  }

  transmit(payload) {
    let client = this.getClient();
    return client.transmit(payload);
  }

  // 2022-05 report vehicles to klaviyo. This is called by
  // the VehicleChooserApp when selection is finalized.
  reportVehicle({customerVehicle}) {
    return this.withUser((user) => {

      const payload = Object.assign({},
        userToKlaviyoProps(user),
        vehicleToKlaviyoProps(customerVehicle));

      const client = this.getClient();

      client.transmit(['identify', payload]);
    });
  }

}
