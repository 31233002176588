import ReactOnRails from 'react-on-rails';

// Components.

// Demo component for testing things.
//import HelloWorld from '../HelloWorld/HelloWorld';

// -- all pages
import AgentStatusApp from '../AgentStatus/AgentStatusApp';
import VehicleChooserApp from "../VehicleChooser/container/VehicleChooserApp";
import ShowCustomerVehicleApp from "../ShowCustomerVehicle/ShowCustomerVehicleApp";
import VehicleYearChooserApp from "../VehicleYearChooser/VehicleYearChooserApp";
import EyeInTheSkyApp from "../EyeInTheSky/EyeInTheSkyApp";
import TubeHelper from "../Tubes/TubeHelper";

// cart/checkout
import ShoppingCartApp from '../ShoppingCart/container/ShoppingCartApp';
import SaveCartApp from '../SaveCart/container/SaveCartApp';
import CheckoutApp from '../Checkout/container/CheckoutApp';

//
// These components can be used in an app or can exist at top level.
//
import TextToClipboard from "../Common/components/TextToClipboard";  // can be top-level
import RevealContentButton from "../Common/components/RevealContentButton";
import ScrollToProductsButton from "../VehicleChooser/components/ScrollToProductsButton";
import DynamicCartApp from "../DynamicCart/container/DynamicCartApp";  // top-level
import CartIconApp from "../CartIcon/CartIconApp";  // top-level
import CompareItemsApp from "../CompareItems/CompareItemsApp";
import SubscribeCheckbox from "../Common/components/SubscribeCheckbox";
import ReportOrderCompleteApp from "../OrderComplete/ReportOrderCompleteApp";
import PropsDumper from "../Utility/Dumper/PropsDumper";
import CarouselApp from "../Carousel/CarouselApp";
import PhoneHoursApp from "../PhoneHours/PhoneHoursApp";
import OpenModalButton from "../Modals/OpenModalButton";
import RequestReceiptApp from "../OrderReceipts/RequestReceiptApp";
import GroupPageApp from "../ProductGroupPage/containers/GroupPageApp";
import ZoidbergApp from "../Zoidberg/container/ZoidbergApp";
import ExploreStrangeNewWorldsApp from "../Demo/ExploreStrangeNewWorldsApp";

ReactOnRails.register({
  // redux-using apps
  AgentStatusApp,
  CartIconApp,
  CheckoutApp,
  CompareItemsApp,
  DynamicCartApp,
  EyeInTheSkyApp,
  ReportOrderCompleteApp,
  RevealContentButton,
  SaveCartApp,
  ShoppingCartApp,
  ShowCustomerVehicleApp,
  SubscribeCheckbox,      // 2023-02 a standalone "subscribe" checkbox. used in new_checkout/completions
  VehicleChooserApp,
  VehicleYearChooserApp,
  PropsDumper,
  OpenModalButton,
  RequestReceiptApp,
  GroupPageApp,
  // containerless widgets
  ScrollToProductsButton,
  TextToClipboard,
  TubeHelper,
  CarouselApp,
  PhoneHoursApp,
  ZoidbergApp,
  ExploreStrangeNewWorldsApp
});
