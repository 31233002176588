import LocalUrlMaker from "../../lib/LocalUrlMaker";
import {camelizeKeysDeep, snakeifyKeysDeep} from "../../ReduxStore/Helpers/transformObject";

export function vehicleToKlaviyoProps(vehicle) {
  vehicle = camelizeKeysDeep(vehicle);

  if (!vehicle || !vehicle.id) return null;

  const makeName = vehicle.shortMakeName || vehicle.vehicleMakeName;
  const modelName = vehicle.shortModelName || vehicle.vehicleModelName;

  let out = {
    Vehicle: `${vehicle.year} ${makeName} ${modelName}`,
    VehicleId: vehicle.id,
    VehicleMake: makeName,
    VehicleModel: `${makeName} ${modelName}`,

    // special case - a jeep wrangler will be sent with type=jeep
    VehicleType: (modelName == "Wrangler") ? "Jeep" : vehicle.vehicleType,

    VehicleYear: vehicle.year,
    // full url with https://whatever
    VehicleURL: vehicle.vehicleUrl ? LocalUrlMaker.url(vehicle.vehicleUrl) : null
  };
  return out;
}

/**
 * Transform an AagUser into Klaviyo predefined property names.
 *
 * @param user - POJO user, snake_case or camelCase OK
 * @returns {{}}
 */
export function userToKlaviyoProps(user) {
  if (!user)
    return {};

  user = snakeifyKeysDeep(user);

  let props = {};

  let prop_names = ['email', 'first_name', 'last_name', 'city', 'region', 'zip'];
  for (let i in prop_names) {
    let k = prop_names[i];
    if (user[k])
      props['$' + k] = user[k];
  }

  return props;
}

export function productToKlaviyoProps(product) {
  product = camelizeKeysDeep(product);

  return ({
    Title: product.productName,
    ItemId: product.productId,
    Categories: product.categories,
    ImageUrl: product.imageUrl,
    Url: product.url,
    Metadata: {
      Brand: product.brand,
      Price: product.price
      // CompareAtPrice: item.compareAtPrice
    }
  })
}
