/*
 * LiveChat integration -
 * LiveChat is loaded in header_scripts.
 * This module simply reconfigures it after it loads.
 */
import SpeakerToAnything from "./SpeakerToAnything";

import UserStash from '../User/UserStash';

//2023-05 Loading migrated to footer_scripts

export default class SpeakerToJustuno extends SpeakerToAnything {
  isLoaded() {
    // A juapp does not exist - never invoked loader function
    // B external script failed - loader function will still
    //   create a simple window.juapp like:
    //   ƒ () { (i[r].q = i[r].q || []).push(arguments);}
    // C external script succeeded - window.juapp is replaced
    //  with a much bigger function.

    return (typeof globalThis.juapp == "function");
  }

  preconfigure() {
    if (this.getAgent())
    {
      console.log("SpeakerToJustuno: Agent detected, suppressing Justuno init.");
      this.setEnabled(false);
      return false;
    }
  }

  /**
   * After acquiring user from Justuno, send
   * to Klaviyo and our own stash.
   */
  saveUser(email) {
    if (!UserStash) {
      console.error("no UserStash defined");
      return;
    }

    /* Save to User Stash */
    const stash = new UserStash();

    const user = stash.saveCurrent({
      email: email, source: 'ju'
    });

    user.sendToKlaviyo();

    stash.sendToCart(user);
  }

  handleEvent(action, promoId) {
    console.log("Handle Justuno Event:", action, promoId)

  }

  api() {
    return window.juapp;
  }
}
