import React from 'react';

import GoogleViewCartReporter from "./GoogleViewCartReporter";
import AdobeViewCartReporter from "./AdobeViewCartReporter";
// import AdobeViewCartReporter from "./AdobeViewCartReporter";
// import {isNola} from "../../../lib/EmbeddedConfig";

// 2023-05 Report only to Google; Facebook lacks a View Cart reporting need
const ViewCartReporter = props => {
  return (
    <div>
      <GoogleViewCartReporter {...props} />
      <AdobeViewCartReporter {...props} />
    </div>
  );
}

// ViewCartReporter.propTypes = { }

export default ViewCartReporter;

