export default class AagUser {
  constructor(attrs) {
    if (attrs) {
      _.extend(this, attrs);
    }
  }

  // DO NOT CHANGE THIS it is used for typechecking!
  isAagUser() { return true; }

  update(attrs) {
    if (attrs) {
      _.extend(this, attrs);
    }
  }

  sendToKlaviyo() {
    withService('klaviyo', function (klaviyo) {
      let client = klaviyo.getClient();
      if (client)
        client.setUser(this);
      else
        console.error("cannot send user to klaviyo");
    });
  }

  // var s = new UserStash();
  // var u = s.current();
  // alert(u.toCartParams())
  toCartParams(pfx) {
    if (!pfx)
      pfx = "customer";

    const params = {};

    const fields = ['email', 'first_name', 'last_name', 'source'];
    for (const i in fields) {
      const k = fields[i];
      const value = this[k];
      if (!value)
        continue;

      const outkey = pfx + "[" + k + "]";
      params[outkey] = value;
    }

    return params;
  }
}
