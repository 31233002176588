import React, {Component, useEffect} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ChatInfoSaver from "./ChatInfoSaver";
import CatchErrors from "../Utility/CatchErrors";
import ErrorModal from "./ErrorModal";
import scrapyardSlice from "../ReduxStore/scrapyardSlice";
import FancyboxContainer from "./FancyboxContainer";

const isDev = (process.env.NODE_ENV == 'development');

function extractCartCode({cartOverview={}, cartSlice={}}) {
  const { shoppingCart } = (cartSlice||{});

  let cc = shoppingCart && shoppingCart.cartCode;
  if (cc)
    return cc;

  return cartOverview && cartOverview.cartCode;
}

export default function EyeInTheSkyContainer(props) {
  const { chatInfo = null, error = null, dispatch } = props;

  // cartOverview, cart, scrapyard, dispatch

  const cartCode = extractCartCode(props);

  // 2024-03 set current agent into redux on startup
  useEffect(() => {
    dispatch(scrapyardSlice.actions.setItems(
      _.pick(props, 'currentAgent')
    ));
  }, []);

  return (
    <div className="eye-in-the-sky">
      <CatchErrors invisible={true}><ChatInfoSaver cartCode={cartCode} chatInfo={chatInfo} dispatch={dispatch}/></CatchErrors>

      {isDev && false &&
        <div className="debug container"><pre>{JSON.stringify(_.keys(props), null, 2)}</pre></div>}

      {error && error.message && <ErrorModal dispatch={dispatch} error={error} />}
      <CatchErrors invisible={!isDev}><FancyboxContainer bindTargets="fancybox"/></CatchErrors>
    </div>
  );
  //
  // <div className="container debug text-xl2">
  //   {JSON.stringify(scrapyard,null,2)}
  // </div>
}

