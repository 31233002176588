//
// DO NOT EDIT THIS FILE! All changes will be overwritten.
// edit the master in app/javascript/themes/snowplows/_template_config.js,
// then run script/activate-theme snowplows
//

//
//    ####   #    #   ####   #    #  #####   #        ####   #    #   ####
//   #       ##   #  #    #  #    #  #    #  #       #    #  #    #  #
//    ####   # #  #  #    #  #    #  #    #  #       #    #  #    #   ####
//        #  #  # #  #    #  # ## #  #####   #       #    #  # ## #       #
//   #    #  #   ##  #    #  ##  ##  #       #       #    #  ##  ##  #    #
//    ####   #    #   ####   #    #  #       ######   ####   #    #   ####
//
// This file is installed into config/javascript/siteConfigVars.js
// run script/activate-theme after making any changes

export const SITE_CONFIG = {
  theme: 'snowplows',
}


//
// config/javascript/siteConfigVars.js
// installed by ./script/activate-theme snowplows
// at Mon 19 Aug 2024 09:22:34 PM UTC on misterplow02
//
