/*
 * LocalApi object -
 * invoked from ClientOnly/initRedux.js, which creates a window.localApi instance.
 *
 */
import Orchestrator from '../lib/Orchestrator';
import PageNavigation from './Navigation/PageNavigation';
import PageEvents from './Events/PageEvents';
import ExternalServices from '../ExternalServices/ExternalServices';
import EmbeddedConfig from "../lib/EmbeddedConfig";

import UserStash from '../User/UserStash';
import JqueryStartup from './JqueryStartup';

import PhoneConnector from './PhoneConnector';
import PageLogicFactory from './PageLogicFactory';
import AgentTools from './AgentTools';
import QueueRunner from '../lib/QueueRunner';
import ErrorHandler from "./Errors/ErrorHandler";
import {handleConsentChange} from "../ExternalServices/OneTrust/cookieConsent";

const isDev = (process.env.NODE_ENV == 'development');

export default class LocalApi extends Orchestrator {

  innerStartup() {

    this.safely("load config",
      () => this.appConfig = new EmbeddedConfig());

    // 2023-02 register window.onerror. Note that affirm will install one
    // of their own, which looks like:
    //     ƒ (){var r=Array.prototype.slice.call(arguments,0);t(e,n,o,r)}
    // ...but theirs will call mine afterwards
    this.safely("create error handler",
      () => { ErrorHandler.install({config: this.appConfig}) });

    // Set up subscriptions right away, as some of the later
    // objects use this, but delay activation until later.
    this.safely("setup events",
      () => this.pageEvents = globalThis.pageEvents = new PageEvents());

    this.safely("load externals",
      () => this.services = new ExternalServices().startup(this));

    // reload stashed user, if any, and send to klaviyo
    // DEPENDS: Klaviyo
    this.aag_user = this.safely("user from stash",
      () => this.user_stash = new UserStash().startup(this));

    // Bind to various elements with jquery.
    this.safely("jquery startup",
      () => new JqueryStartup().startup(this));

    this.safely("activate phone connector",
      () => this.phoneConnector = new PhoneConnector().startup(this));

    // hamburger menu, pulldown menu bars...
    this.safely("page nav",
      () => this.pageNavigation = new PageNavigation().startup(this));

    this.safely("agent tools",
      () => this.agentTools = new AgentTools());

    // Begin watching events.
    this.safely("init events",
      () => this.pageEvents.startup());

    this.safely("load page logic",
      () => this.loadPageLogic());

    this.safely("init modals", initModals);

    this.safely("run queue",
      () => this.queueRunner = new QueueRunner().startup(this));

  }

  loadPageLogic() {
    let f = new PageLogicFactory();
    this.page_logic = f.createForPage();
    this.page_logic.startup(this);
  }

  // 2018-12 not sure if this is still used
  debug(msg) {
    if (window.console) {
      return window.console.debug("debug: " + msg);
    }
  }

  // Main entry point, called immediately after constructor.
  init() {
    if (this._did_init) {
      this.error("LocalApi - called init a second time");
    } else {
      this._did_init = true;

      // this.safely( () => this.initUnsafe() );
      this.innerStartup();
    }
    return this;
  }

  onConsentChange() {
    handleConsentChange();
  }
}

export function initModals(parent) {
  parent ||= $('body')
  let toggles = parent.find('.modal-state');

  console.log(`initModals called; found ${toggles.length} toggles`);

  // 2022-06 For React apps use the .quasimodal classes. See DynamicCartModal
  toggles.off('change');
  parent.find(".modal-fade-screen, .modal-close").off("click");
  parent.find(".modal-inner").off("click");

  toggles.on("change", function () {
    if ($(this).is(":checked")) {
      console.log("modal: changing to OPEN state")
      $("body").addClass("modal-open");
    } else {
      console.log("modal: changing to CLOSED state")
      $("body").removeClass("modal-open");
    }
  });

  parent.find(".modal-fade-screen, .modal-close").on(
    "click", closeLegacyModals);

  parent.find(".modal-inner").on("click", e => {
    // console.log("modal-inner default handler");
    e.stopPropagation();
  });
}

/**
 * Close the Bourbon modal (not the React quasimodal)
 */
export function closeLegacyModals() {
  console.log("Closing modal...");

  // caution, use no arrow functions with jquery
  $('.modal-state').each(function () { this.checked = false; });

  removeModalOpenClass();

  // $(".modal-state:checked").prop("checked", false).change();

  // Also try another way.
  let checkedList = $(".modal-state:checked");
  // $(".modal-state:checked").prop("checked", false).change();
  // console.log(`${checkedList.length} modal checkboxes remain checked`)
  checkedList.removeProp("checked");

  removeModalOpenClass();
}

function removeModalOpenClass() {
  $("body").removeClass("modal-open");
}
