/**
 * Note that most of this is written in antiquated Javascript style,
 * to be compatible with the legacy app.
 *
 * 2018-03: Sends user events to Klaviyo service.
 * 2019-02: no longer shared with legacy app.
 */
import AgentDetector from '../../ClientOnly/AgentDetector';
import CustomerVehicleReader from '../../ClientOnly/CustomerVehicleReader';
import {productToKlaviyoProps, userToKlaviyoProps} from "./KlaviyoHelpers";
import {boldlyLog} from "../../Utility/logging";

// for debugging
const isDev  = (process.env.NODE_ENV == 'development');

export default function KlaviyoClient(attrs) {
  if (attrs)
    _.extend(this, attrs);
  this.history || (this.history = []);
}

// class method.
KlaviyoClient.getInstance = function (root) {
  if (!root) {
    root = window;
  }

  root.klaviyo || (root.klaviyo = new KlaviyoClient());
  return root.klaviyo;
};

KlaviyoClient.prototype.sendViewedProduct = function (product) {
  this.track('Viewed Product', product);
  // _learnq.push(['track', 'Viewed Product', item]);

  this.transmit(['trackViewedItem', productToKlaviyoProps(product)]);
};

KlaviyoClient.prototype.setUser = function (user) {
  this.user = user;
  if (this.user)
    this.sendIdentify();
};

KlaviyoClient.prototype.pushHistory = function (event) {
  this.history || (this.history = []);
  this.history.push(event);
};

// Never cache this - it will change when klaviyo finishes loading
KlaviyoClient.prototype.getQueue = function () {
  if (!window._learnq)
    window._learnq = [];

  var _learnq = window._learnq;
  console.log("AAG: KlaviyoClient: got queue " + typeof (_learnq));
  return _learnq;
};



KlaviyoClient.prototype.sendIdentify = function (attrs = {}) {
  let props = userToKlaviyoProps(this.user);
  let payload = _.extend(props, this.getVehicle(), attrs);

  const cc = window.cartConnector;
  if (cc && cc.cart_code) {
    payload.CartCode = cc.cart_code;
  }

  this.transmit(['identify', payload]);
};

// event should be an array; sometimes two, sometimes three members
// identify,  { $email : ... }
// track, event_name, { stuff }
KlaviyoClient.prototype.transmit = function (payload) {
  if (this.isAgent()) {
    console.log("Klaviyo Transmit - skipping for Agent; suppressed content is " + JSON.stringify(payload));
    return false;
  } else {
    var q = this.getQueue();
    q.push(payload);

    this.pushHistory(payload);

    boldlyLog("Sent Klaviyo event:\n" + JSON.stringify(payload, null, 2));
  }
};

KlaviyoClient.prototype.track = function (event_name, attrs) {
  return this.transmit(["track", event_name, attrs]);
};

KlaviyoClient.prototype.isAgent = function () {
  var d = new AgentDetector();
  return d.getAgent() ? true : false;
};

KlaviyoClient.prototype.getVehicle = function (vehicle) {
  let reader = new CustomerVehicleReader();
  return reader.getVehicle() || {};
};

