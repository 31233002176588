import React, {Component} from 'react';
import _ from 'lodash';
import {Provider, connect} from 'react-redux';
import ShoppingCartContainer from "./ShoppingCartContainer";
import {getReduxStore} from "../../ReduxStore/store";

function mapStateToProps(state) {
  let {cart = {}, vehicleSlice = {}, saveCart = {}, scrapyardSlice = {}} = state;

  // omit: cartItemId, contextKeys, callInNumber...
  return Object.assign(
    _.pick(saveCart, 'saveCartStatus'),
    _.pick(cart,
      'shoppingCart', 'dynamicCart', 'newItems',
      'recommendations', 'forecasts', 'itemErrors'),
    _.pick(vehicleSlice, 'customerVehicle'),
    _.pick(scrapyardSlice, 'braintreeData', 'currentAgent')  // 2023-06 braintree token lives here now.
  );
}

let Wrapper = connect(mapStateToProps)(ShoppingCartContainer);

export default function ShoppingCartApp({...props}) {
  return <Provider store={getReduxStore()}>
    <Wrapper {...props} />
  </Provider>
}

