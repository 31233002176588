import React from 'react';
import PropTypes from 'prop-types';
import LocalUrlMaker from "../lib/LocalUrlMaker";

export default function ModernCartIcon(props) {
  let {cartCode, cartUrl, itemCount} = props;

  cartUrl ||= LocalUrlMaker.prefixedPath(`/cart/${cartCode}`)

  return (
    <div className="top-cart-display">
      <a href={cartUrl} rel="nofollow">
        <i className="fas fa-shopping-cart"/>
        {(+itemCount) > 0 && <div className="cart-count">{itemCount}</div>}
      </a>
    </div>
  );
}


