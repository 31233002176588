/**
 * 2023-05 v3.0 - class syntax, nix jquery cookie, use new universal-cookie library
 *
 * FIXME: this class does far too much, it's poorly documented, its full of legacy jquery crap
 *
 */

import CartCodeFinder from './CartCodeFinder';
import CartCodeDisplay from './CartCodeDisplay';
import LegacyCartCountDisplay from './LegacyCartCountDisplay';
import EmbeddedConfig from "../lib/EmbeddedConfig";
import LocalUrlMaker from "../lib/LocalUrlMaker";
import AgentDetector from "../ClientOnly/AgentDetector";
import {garbage} from "../lib/deprecation";

// 2024 used by PhoneConnector, KlaviyoClient, SpeakerToLiveChat
export default class CartConnector {
  constructor(opts = {}) {
    this.VERSION = 3.0;
    _.extend(this, opts);
    this.create_url = LocalUrlMaker.jsonPath("/cart.json");
    garbage("CartConnector init")
  }

  run() { return this.call() }

  call() {
    this.cart_code = this.codeFinder().getCartCode();

    // already have a cart?
    if (this.cart_code) {
      this.codeDisplay().displayId(this.cart_code);
      return this.countDisplay().update();
    } else {
      // no cart... first show the empty display...
      this.countDisplay().update();
      this.codeDisplay().showEmpty();

      // and maybe create a cart
      this.initWithoutCart();
    }
  }

  // 2019-02 - send these variables to LiveChat
  // canyonero SpeakerToLiveChat will call upon this.
  chatVariables() {
    let cart_code = this.cart_code;

    const conf = new EmbeddedConfig();

    let baseUrl = location ? (location.protocol + '//' + location.host) : "";

    let vars = {
      // they show in alpha order, we want this at the top
      AASite: conf.fetch('display_name') || conf.fetch(name),
      CartURL: baseUrl
    }

    if (cart_code) {
      return Object.assign(vars, {
        ConnectID: this.cart_code,
        CartURL: (baseUrl + LocalUrlMaker.prefixedPath("/cart/" + cart_code + "?agent_connect=1")),
      });
    } else {
      return vars;
    }
  }

  railsStartup() {
    this.cart_code = this.codeFinder().getCartCode();
    if (this.cart_code) {

    } else {
      this.initWithoutCart();
    }
  }

  initWithoutCart() {
    garbage("CartConnector.initWithoutCart")
    this.bindCreateLinks();

    const pp = this.autoCreateParams();

    if (pp) {
      this.create_reason = 'auto-create2';
      this.createCart(pp);
    }
  }

  isAgent() {
    let detector = new AgentDetector();
    return !!detector.getAgent();
  }

// Create cart automatically if a gclid is present OR we are on a mobile device.
// example:
//  /Lighting/KC-Hilites-Gravity-LED-Daylighter-Lights/_Item/_606400006?
//       utm_source=google&utm_medium=Lighting&utm_campaign=GoogleProducts&utm_term=KC-Hilites-Daylighter-with-Gravity-G6-LED-
// Lights&gclid=ABcdefGHijklMNopqrSTuvwxYz
  autoCreateParams() {
    const params = $.parseQuery();

    const gclid = params.gclid;
    const mobile = this.isMobile();

    // NEVER autocreate for agents. 2018-02
    if (this.isAgent())
      return false;

    // alert(`gclid=${gclid} agent=${this.isAgent()} mobile=${mobile}`);

    // Continue if there is a gclid or we are on a mobile device.
    if ((!gclid) && (!mobile))
      return false;

    const attrs = {};
    if (gclid) {
      attrs["shopping_cart[created_by]"] = "GoogleAdClick";
      attrs["shopping_cart[incoming_click_id]"] = gclid;
    } else {
      attrs["shopping_cart[created_by]"] = "AutoCreate";
    }

    // Copy utmKeys to incoming_params member of cart.
    // WARNING: _ might be either lodash or underscore, don't use _.at() here
    const utmKeys = _(["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"]);
    attrs["shopping_cart[incoming_params]"] =
      utmKeys.map(function (k) { return params[k] || "" })
        .join("|");

    return attrs;
  }

  isMobile() {
    // Rails
    const dd = globalThis.device_detector || globalThis.deviceDetector;
    if (dd && dd.isMobile)
      return dd.isMobile();

    // legacy
    return "mobile" == window.deviceMajor;
  }

  codeFinder() {
    return this._code_finder || (this._code_finder = new CartCodeFinder());
  }

  codeDisplay() {
    return this._code_display || (this._code_display = new CartCodeDisplay());
  }

  countDisplay() {
    return this._count_display || (this._count_display = new LegacyCartCountDisplay());
  }

  bindCreateLinks() {
    var self = this;
    $('.request-cart-code').click(function () {
      return self.createCart();
    });
  }

  createCart(params) {
    params = params || {};
    console.log("Creating cart...");
    console.dir(params);

    return $.post(this.create_url, params, (function (_this) {
      return function (response) {
        _this.receiveCart(response);
        _this.receiveCallInNumber(response);
      };
    })(this));
  }

  receiveCart(response) {
    // { call_in_number: "123-456-7890", shopping_cart: { cart_code: XXX...} }
    var cart = response.shopping_cart;

    if (cart && cart.cart_code) {
      this.cart_code = cart.cart_code;

      this.codeDisplay().displayId(this.cart_code);
    } else {
      window.location = "/cart/";
    }
  }

// The create-cart response will also include a call-in-number.
// For users starting in the legacy shitpile, this is the first contact
// it makes with the rails app, therefore the first time this number
// becomes known. Later page loads will use the cin17 cookie.
  receiveCallInNumber(response) {
    var call_in_number = response.call_in_number;
    if (!call_in_number)
      return;

    var phoneFixer = window.phoneFixer;
    if (phoneFixer) {
      try {
        phoneFixer.updateLinks(call_in_number);
        phoneFixer.updateDisplay(call_in_number);
      } catch (err) {
        alert("phone fixer error " + err);
      }
    } else {
      console.error("No phone fixer, cannot edit tel: links");
    }
  }
}
