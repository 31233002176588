/*
 * SHARED WITH BOTH PUBLIC APPS - copy from one to the other!
 *
 * UserStash - a stateless class for saving/retrieving user
 * in LocalStorage.
 *
 *
 * localStorage.getItem('aag_user'):
 * '{"email":"hucke+202404@cynico.net","source":"ju",
 * "updated_at":"Tue Apr 09 2024 13:42:49 GMT-0700 (Pacific Daylight Time)"}'
 *
 */

import AagUser from './AagUser';

const CURRENT_USER_KEY = "aag_user";

export default class UserStash {
  constructor() {
    this.localStorage = window.localStorage;
  }

  // Basic operations - find, save, delete

  find(key) {
    if (!this.localStorage) return null;

    const encoded = localStorage.getItem(key);
    if (!encoded)
      return null;

    return new AagUser(JSON.parse(encoded));
  }

  save(key, user) {
    if (!this.localStorage) return null;

    if (!user.isAagUser)
      user = new AagUser(user);

    user.updated_at = new Date().toString();
    const encoded = JSON.stringify(user);

    this.localStorage.setItem(key, encoded);
    return user;
  }

  findOrBuild(key) {
    let user = this.find(key);
    if (!user)
      user = new AagUser();
    return user;
  }

  delete(key) {
    if (!this.localStorage) return null;

    this.localStorage.removeItem(key);
  }

  // 2022-05 migrate to this instead of badly named current()
  getUser() {
    return this.find(CURRENT_USER_KEY);
  }

  // current user operations
  current(always) {
    if (always)
      return this.findOrBuild(CURRENT_USER_KEY);
    else
      return this.find(CURRENT_USER_KEY);
  }

  // Apply new attributes to current user.
  updateCurrent(attrs) {
    let user = this.current(true);

    // if email is changing, throw out old user - if any.
    if (attrs.email && (attrs.email != user.email)) {
      console.log("Email mismatch with " + user.email + ", clearing.");
      this.clearCurrent();
      user = new AagUser(attrs);
    }

    user.update(attrs);
    this.saveCurrent(user);
    return user;
  }

  saveCurrent(user) {
    return this.save(CURRENT_USER_KEY, user);
  }

  clearCurrent() {
    return this.delete(CURRENT_USER_KEY);
  }

  // 2018-05-02 - send to shopping cart
  // var user = new UserStash().current();
  // new UserStash().sendToCart(user)
  sendToCart(user) {
    if (!user.toCartParams)
      user = new AagUser(user);
    const pp = user.toCartParams("customer");

    // FIXME jQuery AJAX
    $.post("/cart_engine/customers", pp, function (response) {
      /* IGNORED */
      console.dir(response);
    });
  }

  // 2018-12 called from canyonero localApi init
  // return user or nil
  startup(page) {
    let u = this.current(false);

    if (u) {
      u.sendToKlaviyo();
    }

    return this;
  }
}

window.UserStash = UserStash;
