
// Read the current customer vehicle from Redux.

import ReduxReader from "../Utility/ReduxReader";
import LocalUrlMaker from "../lib/LocalUrlMaker";

/**
 * Read customer vehicle from Redux.
 *
 */
export default class CustomerVehicleReader {
  getVehicle() {
    let rr = new ReduxReader();
    let vehicle = rr.getObject("vehicleSlice", "customerVehicle");

    return vehicle ? this.convertToKlaviyoVehicle(vehicle) : {};
  }

  convertToKlaviyoVehicle = function (obj) {
    if (!obj || !obj.id) return null;

    let vehicle =  _.mapKeys(obj, (_val, k) => _.camelCase(k));

    let make = vehicle.shortMakeName || vehicle.vehicleMakeName;
    let model = vehicle.shortModelName || vehicle.vehicleModelName;

    let out = {
      Vehicle: `${vehicle.year} ${make} ${model}`,
      VehicleId: vehicle.id,
      VehicleMake: make,
      VehicleModel: `${make} ${model}`,
      // special case - a jeep wrangler will be sent with type=jeep
      VehicleType: (model == "Wrangler") ? "Jeep": vehicle.vehicleType,
      VehicleYear: vehicle.year,
      VehicleURL: LocalUrlMaker.url(vehicle.vehicleUrl) // full url with https://whatever
    };
    return out;
  };
}

